<template>
  <div class="lease">
    <page-nav title="我的租赁" />
    <van-empty v-show="!loading && list.length == 0" description="暂无租赁记录" />
  </div>
</template>

<script>
import PageNav from '../../components/PageNav.vue';
export default {
  name: 'Lease',
  data() {
    return {
      loading: false,
      list: [],
    };
  },
  components: { PageNav },
}
</script>